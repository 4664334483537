import React from "react";
import decode from "jwt-decode";
import qs from "query-string";
import PinwheelModal from '@pinwheel/react-modal'

export type JarvisTerminalError = { errorCode: string; errorMsg: string };

const modalOverrides = {
  _sdkOverride: 'standalone-site',
  _srcUrl: process.env.REACT_APP_LINK_TOKEN_MODAL_SCRIPT_URL
}


const serverConfigForMode = (mode: string) => {
  switch (mode) {
    case "sandbox":
      return (
        process.env.REACT_APP_HUB_SANDBOX_API_SERVER ||
        "https://staging.sandbox.getpinwheel.com"
      );
    case "development":
      return (
        process.env.REACT_APP_HUB_DEVELOPMENT_API_SERVER ||
        "https://staging.development.getpinwheel.com"
      );
    case "production":
      return (
        process.env.REACT_APP_HUB_PRODUCTION_API_SERVER ||
        "https://staging.api.getpinwheel.com"
      );
  }
};

function App() {
  const { token, decodedToken } = React.useMemo(() => {
    const { token } = qs.parse(window.location.search) as {token: string};
    return { decodedToken: decode(token), token };
  }, []);

  const redirect = React.useCallback(
    () => {
      const domain = serverConfigForMode(decodedToken.data.mode);
      let redirectUrl = `${domain}v1/sites/redirect?link_token=${token})}`;
      (window as any).location = redirectUrl;
    },
    [token, decodedToken]
  );

  return (
    <PinwheelModal
      {...modalOverrides}
      open={true}
      linkToken={token}
      onExit={redirect}
    /> 
  );
}

export default App;
